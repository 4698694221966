







































































import Vue from "@/translate";
import LanguageIcon from "@/components/LanguageIcon.vue";
import ColorsMixin from "@/mixins/color-scheme";
import { Store } from "vuex";
import { RootState } from "@/store/types";
import { CHANGE_LANGUAGE, LOGOUT } from "@/store/actions";

export default Vue.extend(ColorsMixin).extend({
  name: "UserMenu",
  components: { LanguageIcon },
  props: {
    auto: {
      type: Boolean,
      required: true,
    },
    mini: {
      type: Boolean,
      required: true,
    },
    settingsPage: {
      type: String,
      required: true,
    },
    i18nMenuTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    timezone(): string {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    userId(this: { $store: Store<RootState> }): number {
      return this.$store.state.user?.id ?? -1;
    },
    username(this: { $store: Store<RootState> }): string {
      return this.$store.state.user?.login ?? "";
    },
    isAdmin(): boolean {
      return this.$store.state.user?.role?.admin || false;
    },
    isManager(): boolean {
      return this.$store.state.user?.role?.manager || false;
    },
    isWorker(): boolean {
      return this.$store.state.user?.role?.worker || false;
    },
  },
  methods: {
    updateAuto(): void {
      this.$emit("update:auto", !this.mini);
    },
    openSettings(): void {
      this.$router.push({ name: this.settingsPage });
    },
    async switchLang(this: { $store: Store<RootState> }): Promise<void> {
      await this.$store.dispatch(CHANGE_LANGUAGE);
    },
    switchToAdmin(): void {
      this.$router.push({ name: "Admin" });
    },
    switchToManager(): void {
      this.$router.push({ name: "MProjectList" });
    },
    switchToWorker(): void {
      this.$router.push({ name: "WorkerJobs" });
    },
    logout(): void {
      this.$store.dispatch(LOGOUT);
    },
  },
});
